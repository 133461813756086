<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative" v-if="zoneDetails">
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="zoneDetails.hasOwnProperty('image') &&
            zoneDetails.image != '' &&
            zoneDetails.image != null
            ? zoneDetails.image
            : zoneImgURL
            " :lazy-src="zoneDetails.hasOwnProperty('image') &&
            zoneDetails.image != '' &&
            zoneDetails.image != null
            ? zoneDetails.image
            : zoneImgURL
            " alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4">
              You are parked at
              {{
            zoneDetails.hasOwnProperty("zoneName")
              ? zoneDetails.zoneName
              : ""
          }}
            </div>
          </div>
          <v-card-text class="pa-2">
            <v-form ref="detailsForm">
              <v-container fluid pa-0>
                <v-row no-gutters class="mb-3 pb-2">
                  <v-col cols="12" class="px-4 mt-2 text-center color-black">
                    <p class="mb-0 black--text" style="font-size: 15px; text-align: justify">
                      Find your session to apply the discount to your parking
                      session
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2">
                  <v-col cols="1" class="text-left align-self-center">
                    <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                  </v-col>
                  <v-col cols="11" class="px-2">
                    <VuePhoneNumberInput v-model="contactObj.phoneNumber" size="md" @update="onContactInput"
                      show-code-on-list :default-country-code="contactOptions.defaultCountry"
                      :disabled="contactDisabled" :color="contactOptions.color" :valid-color="contactOptions.validColor"
                      :error-color="contactOptions.errorColor" :border-radius="contactOptions.contactBorderRadius"
                      :error="!isContactValid" required :translations="contactOptions.translations" />
                  </v-col>
                </v-row>
                <v-row no-gutters class="pr-2 mt-2">
                  <v-col cols="1" class="text-left align-self-center">
                    <v-icon class="material-icons-outlined">pin</v-icon>
                  </v-col>
                  <v-col cols="5" class="pl-2">
                    <v-select :items="validationTypes" v-model="selectedValidationType" class="validation-type-select"
                      append-icon="mdi-chevron-down" hide-details="auto"></v-select>
                  </v-col>
                  <v-col cols="6" class="pl-1" v-if="selectedValidationType == 'lp'">
                    <v-text-field v-model="licencePlate" hide-details="auto" maxlength="10"
                      :rules="[rules.isRequired, rules.alphaNumeric]" label="License Plate *" @keyup="uppercase"  clearable></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pl-1" v-else>
                    <v-text-field v-model="ccDigits" hide-details="auto" maxlength="4"
                      :rules="[rules.isRequired, rules.isNumeric, rules.exactCharacters(4)]" type="number"
                      label="Last 4 digits *" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-2" v-if="selectedValidationType == 'cc'">
                  <v-col cols="1" class="text-left align-self-center">
                    <v-icon class="material-icons-outlined">date_range</v-icon>
                  </v-col>
                  <v-col cols="11" class="pl-1">
                    <v-menu v-model="ccExpiryMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="formatCCExpiry" label="Expiry Date (MM/YY) *" readonly v-bind="attrs"
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#f2555c"  :min="minExpiry" v-model="ccExpiry" type="month" @input="ccExpiryMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center py-8">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    :loading="disableFindSessionLoadingBtn" :disabled="disableFindSessionContinueBtn"
                    @click="findActiveSession()">Continue</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="activeSessionFoundDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title style="
            font-size: 15px;
            white-space: normal;
            line-height: 1.5;
            word-break: keep-all;
            font-weight: 500;
          ">
          <v-row no-gutters>
            <v-col cols="1" class="align-self-center">
              <v-icon class="material-icons-outlined" color="success">check_circle</v-icon>
            </v-col>
            <v-col cols="10" class="pl-2 align-self-center">
              <p class="mb-0" style="font-size: 15px">Active Session Found</p>
            </v-col>
            <v-col cols="1">
              <v-icon class="material-icons-outlined" @click="activeSessionFoundDialog = false">close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pl-6 mt-2 pb-1">
          <v-row no-gutters class="ma-1 pb-1 black--text">
            <v-col cols="12 black--text" style="letter-spacing: normal">
              <span style="font-size: 15px">Do you want to validate your existing session?</span>
            </v-col>
          </v-row>
          <!-- <v-row
            no-gutters
            class="ma-1 black--text"
            style="font-size: 14px"
            v-if="
              bookingDetails.hasOwnProperty('user') &&
              bookingDetails.user.hasOwnProperty('contact')
            "
          >
            <v-col cols="5" class="align-self-center">
              <span>Contact No</span>
            </v-col>
            <v-col cols="7" class="align-self-center text-end">
              <span>{{
                bookingDetails.user.contact != "" &&
                bookingDetails.user.contact != null
                  ? "+" + bookingDetails.user.contact
                  : "-"
              }}</span>
            </v-col>
          </v-row>
          <v-row
            style="font-size: 14px"
            no-gutters
            class="ma-1 black--text"
            v-if="
              bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.booking.hasOwnProperty('vehicleNo')
            "
          >
            <v-col cols="5" class="align-self-center">
              <span>License Plate</span>
            </v-col>
            <v-col cols="7" class="align-self-center text-end">
              <span>{{
                bookingDetails.booking.vehicleNo != "" &&
                bookingDetails.booking.vehicleNo != null
                  ? bookingDetails.booking.vehicleNo
                  : "-"
              }}</span>
            </v-col>
          </v-row> -->
        </v-card-text>

        <v-card-actions class="pb-6 ml-2">
          <div class="pa-0" v-if="validationCode!=null && validationCode!='' && !isJwtValidationCode" flat style="
              border: 1px solid black;
              height: 40px;
              border-style: dotted;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #e0e0e0;
              border-radius: 10px 10px 10px 10px;
            ">
            <p class="pa-3 ma-0">{{ validationCode }}</p>
          </div>
          <v-spacer></v-spacer>
          <v-btn class="white--text apply_dialog_btn" :loading="applyBtnLoading" color="#f2555c" @click="onValidateText()">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noActiveSessionFound" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-3" style="text-align: justify">
          <span style="font-size: 20px; font-weight: normal">{{
            errorMsg
          }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                  @click="closeNoActiveSessnFndDialog()">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sessionValidated" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-3" style="text-align: justify">
          <span style="font-size: 20px; font-weight: normal">
            Session is already validated
          </span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                  @click="sessionValidated = false">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" persistent>
      <v-card class="pa-4">
        <v-progress-circular indeterminate color="red"></v-progress-circular>
        <span class="ml-4" style="font-size: 14px">Please wait.</span>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import rules from "@/utils/rules";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "CreateSession",
  components: { VuePhoneNumberInput },
  data: () => ({
    ccExpiryMenu: false,
    ccExpiry: null,
    ccDigits: "",
    validationTypes: [{
      text: "Credit Card",
      value: "cc"
    }, {
      text: "License Plate",
      value: "lp"
    }],
    selectedValidationType: "cc",
    rules: rules,
    licencePlate: "",
    disableFindSessionLoadingBtn: false,
    // confirmCreateSessionDialog: true,
    contactDisabled: false,
    isContactValid: true,
    contactObj: {},
    contactOptions: {
      color: "#0288D1",
      validColor: "#0288D1",
      contactBorderRadius: 0,
      errorColor: "#FF5252",
      defaultCountry: "US",
      translations: {
        phoneNumberLabel: "Contact No",
      },
    },
    activeSessionFoundDialog: false,
    validationCode: "",
    noActiveSessionFound: false,
    errorMsg: "",
    bid: "",
    sessionValidated: false,
    invalidSMSMsg: "",
    applyBtnLoading:false
  }),

  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
      zoneDetails: "getterZoneDetails",
    }),
    minExpiry(){
      return new Date().getFullYear()+"-"+(new Date().getMonth()+1)
    },
    loading() {
      return (
        this.$store.getters["getterLoadingStatus"]("APP")
      );
    },
    formatCCExpiry() {
      let splitExpiry = this.ccExpiry ? this.ccExpiry.split("-") : "";
      return splitExpiry.length == 2 ? splitExpiry[1] + "/" + splitExpiry[0].slice(-2) : null;
    },
    disableFindSessionContinueBtn() {
      let disabled = false;
      disabled = disabled || this.isContactValid == false;
      disabled = this.selectedValidationType == "lp" ? disabled || (this.licencePlate == "" ||
        this.licencePlate == null ||
        /^[a-z0-9]+$/i.test(this.licencePlate) === false) : disabled;
      disabled = this.selectedValidationType == "cc" ? disabled || (this.ccExpiry == null || this.ccExpiry == "" || this.ccDigits == null || this.ccDigits == "" || this.ccDigits.length != 4) : disabled;
      return disabled;
    },
    isJwtValidationCode(){
      let self=this;
      const jwtRegex = /^[\w-]*\.[\w-]*\.[\w-]*$/;
      return jwtRegex.test(self.validationCode);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // Digital Validations flow
      // Here, we check whether there is an active session that hasn't been validated,
      //and if there is, we open a popup that notifies user that there is an active session.
      vm.applyBtnLoading = false;
      const query = vm.$route.query.zcode;
      vm.validationCode = vm.$route.query.validationCode;
      vm.getZoneDetails("?zcode", query);
      if (localStorage.getItem("bookingDetails")) {
        let bookingInfo = localStorage.getItem("bookingDetails");
        bookingInfo = bookingInfo ? JSON.parse(bookingInfo) : {};
        const zcode = bookingInfo?.zcode ? bookingInfo.zcode : "";
        vm.bid = bookingInfo?.bid ? bookingInfo.bid : "";
        const bState = await vm.getBookingDetails(vm.bid);
        if (vm.bookingDetails?.booking?.validated === "1") {
          localStorage.removeItem("bookingDetails");
        } else if (
          (bState == 2 || bState == 3 || bState == 4) &&
          zcode == query
        ) {
          if (vm.bookingDetails?.booking?.validated !== "1") {
            vm.licencePlate =
              vm.bookingDetails?.booking?.vehicleNo != null &&
                vm.bookingDetails?.booking?.vehicleNo != ""
                ? vm.bookingDetails?.booking?.vehicleNo
                : "";
            vm.contactObj =
              vm.bookingDetails?.user?.contact != null &&
                vm.bookingDetails?.user?.contact != ""
                ? await Object.assign(vm.contactObj, {
                  phoneNumber: "+" + vm.bookingDetails?.user?.contact,
                })
                : vm.contactObj;
            vm.activeSessionFoundDialog = true;
          } else if (vm.bookingDetails?.booking?.validated == "1") {
            this.sessionValidated = true;
          }
        }
      }
    });
  },
  async mounted() { },
  methods: {
    onContactInput(contactDetails) {
      this.isContactValid = contactDetails?.isValid;
      this.contactObj = Object.assign({}, contactDetails);
    },
    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
      }
    },
    /**
     * @method onValidateText this method we call validateBooking fn which in validates the booking
     */
    async onValidateText() {
      let self = this;
      self.applyBtnLoading = true;
      let result = await this.validateBooking(this.validationCode, "coupon");
      if (!result.data.status) {
        this.invalidSMSMsg = result?.data?.message
          ? result.data.message
          : "Validation not recognized. Please try again";
        this.postToLoggerAPI({
          phone:
            Object.keys(this.contactObj).length !== 0
              ? this.contactObj?.formattedNumber.substring(1)
              : "",
          refId: this.bid,
          error: this.invalidSMSMsg,
          icon: "redeem",
          statusText:
            "Error in validating the session on entering validation code - " +
            self.validationCode,
        });
        let data = {
          message: result?.data?.message,
        };   
        this.$router.replace({ name: "ValidationFailure", params: { data } });    
        await new Promise((resolve) => {
          setTimeout(() => {
            const url = `g/${this.bid}`;
            window.location.replace(url);
            resolve(); // Resolve the promise after the timeout
          }, 3000);
        });
      } else {
        this.postToLoggerAPI({
          phone:
            Object.keys(this.contactObj).length !== 0
              ? this.contactObj?.formattedNumber.substring(1)
              : "",
          refId: this.bid,
          icon: "redeem",
          statusText:
            "Parker validated the session successfully by entering validation code - " +
            self.validationCode,
        });
        this.$store.commit(
          "SET_VALIDATED_MERCHANT",
          result?.data?.merchantName
        );
        this.$router.replace("/validatesuccess");
        await new Promise((resolve) => {
          setTimeout(() => {
            const url = `g/${this.bid}`;
            window.location.replace(url);
            resolve(); // Resolve the promise after the timeout
          }, 3000);
        });
      }
      self.applyBtnLoading = false;
    },
    /**
     * @method validateBooking this method calls the validate api which validates the booking
     */
    async validateBooking(code, type) {
      try {
        let validateResult = await APIHelper(
          "POST",
          "/api/v1/validate/" + this.bid,
          {
            coupon: code,
            validationType: type,
            zid: this.zoneDetails?.zcode,
          }
        );
        return validateResult;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    /**
     * @method getBookingDetails fetch the booking details and commit SET_BOOKING_DETAILS mutation.
     */
    async getBookingDetails(bookingId) {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        return bookingDetails.data?.data?.booking?.state
          ? bookingDetails.data.data.booking.state
          : null;
      } catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        console.log(error);
      }
    },
    /**
     * @method findActiveSession this method check whether there is an active session or not
     */
    async findActiveSession() {
      try {
        let postData = {
          zid: this.zoneDetails?.zid,
          contact:
            Object.keys(this.contactObj).length !== 0
              ? this.contactObj?.formattedNumber.substring(1)
              : "",
          licensePlateORlast4digitsForCC: this.selectedValidationType == "lp" ? this.licencePlate : this.ccDigits,
          ccExpiry: this.ccExpiry ? this.formatCCExpiry.split("/").join(""): ""
        };
        this.disableFindSessionLoadingBtn = true;
        const bookingDetails = await APIHelper(
          "POST",
          "/api/v1/digitalValidationForCC",
          postData
        );
        this.disableFindSessionLoadingBtn = false;
        // this.$store.commit(
        //   "SET_BOOKING_DETAILS",
        //   bookingDetails.data?.data ? bookingDetails.data.data : null
        // );
        let contactNo =
          Object.keys(this.contactObj).length !== 0
            ? this.contactObj?.formattedNumber.substring(1)
            : "";
        if (bookingDetails?.data?.status) {
          if (bookingDetails?.data?.data.validated != "1") {
            this.bid = bookingDetails?.data?.data?.refno;
            this.activeSessionFoundDialog = true;
            this.postToLoggerAPI({
              phone: contactNo,
              refId: this.bid,
              statusText: `${bookingDetails?.data?.message} - /bid-${this.bid}/contactNo-${contactNo}/licencePlateOrCreditCardNo-${this.licencePlate}`,
            });
          } else if (bookingDetails?.data?.data.validated == "1") {
            this.sessionValidated = true;
            this.postToLoggerAPI({
              phone: contactNo,
              refId: this.bid,
              statusText: `Session already Validated - /bid-${this.bid}/contactNo-${contactNo}/licencePlateOrCreditCardNo-${this.licencePlate}`,
            });
          }
        } else if (!bookingDetails?.data?.status) {
          this.postToLoggerAPI({
            phone: contactNo,
            error: `${bookingDetails?.data?.message} - /contactNo-${contactNo}/licencePlateOrCreditCardNo-${this.licencePlate}`
          });
          this.errorMsg = bookingDetails?.data?.message;
          this.noActiveSessionFound = true;
        }
      } catch (error) {
        this.disableFindSessionLoadingBtn = false;
        console.log(error);
      }
    },
    closeNoActiveSessnFndDialog() {
      this.noActiveSessionFound = false;
    },
  },
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 45px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.country-selector.md.has-value .country-selector__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
  box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
  box-shadow: none !important;
}

.lp-textField .v-label {
  font-size: 0.74rem;
}

.apply_dialog_btn {
  width: 40%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  height: 40px !important;
}

.validation-type-select .v-select__selection.v-select__selection--comma {
  font-size: smaller;
}
</style>